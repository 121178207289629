// rem fallback - credits: http://zerosixthree.se/

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// border radius

@mixin border-radius($radius: 0.25em) {
  border-radius: $radius;
}

// antialiasing mode font rendering

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// media queries

@mixin MQ($breakpoint) {
  @if $breakpoint == S {
    @media screen and (min-width: $sm) {
      @content;
    }
  } @else if $breakpoint == M {
    @media screen and (min-width: $md) {
      @content;
    }
  } @else if $breakpoint == L {
    @media screen and (min-width: $lg) {
      @content;
    }
  } @else if $breakpoint == XL {
    @media screen and (min-width: $xl) {
      @content;
    }
  }
}
@mixin flex( $prop: flex, $justify: center, $align: center, $important: null ){
    @if( 'flex' == $prop ){
        display: -webkit-box $important;
    }@else{
        display: -webkit-inline-box $important;
    }
    display: -webkit-#{$prop} $important;
    display: $prop $important;
    @if( 'center' == $justify ){
        -webkit-box-pack: center
    }@else if( 'start' == $justify ){
        -webkit-box-pack: start
    }@else if( 'end' == $justify ){
        -webkit-box-pack: end
    }@else{
        -webkit-box-pack: justify
    }
    justify-content: $justify;
    -webkit-box-align: $align;
    align-items: $align
}

//--------------------------------- Project Mixin:


@mixin fontface($fontfamily,$fontsize,$color,$fontweight) 
{
    font-family: $fontfamily;
    font-size: $fontsize;
    color: $color;
    font-weight: $fontweight;
}

@mixin brgImg($path) 
{
  background-image: url($path);
  background-position: center;
  background-size: cover;
}

@mixin boxShadow ($artr) {
  -ms-box-shadow: $artr;
  -webkit-box-shadow: $artr;
  -moz-box-shadow: $artr;
  box-shadow: $artr;
  -o-box-shadow: $artr;
  
}
@mixin  Rotate($deg) {
  transform: rotate($deg);
-ms-transform: rotate($deg); 
-webkit-transform: rotate($deg); 
-moz-transform:rotate($deg); 
-o-transform:rotate($deg); 
}


@mixin sectionTittle($titt){
  .#{$titt}__tittle {
    margin: 100px 0 65px;
    @media ( max-width: $lg ) {
      margin: 60px 0;
    }
    @media ( max-width: 550px ) {
      margin: 50px 0 30px;
    }
  }
  .#{$titt}__sub-tittle {
    @include fontface($font-primary,13px,$dusty-gray,normal);
    margin-top: 25px;
    display: inline-block;
    width: 270px;
    @media (max-width:475px) {
      width: 100%;
    }
  }
}
@mixin sectionTittleMiddle($titt){
  .#{$titt}__tittle {
    text-align: center;
    p, .section-tittle__line-under{
      left: 50%;
      transform: translate(-50%,0);
    }
    margin: 90px 0 65px;
    @media ( max-width: $lg ) {
      margin: 60px 0;
    }
    @media ( max-width: 550px ) {
      margin: 50px 0 30px;
    }

  }
  .#{$titt}__sub-tittle {
    @include fontface($font-primary,13px,$dusty-gray,normal);
    margin-top: 25px;
    display: inline-block;
    width: 370px;
    @media (max-width:475px) {
      width: 100%;
    }
  }
}
@mixin sectionTittleRight($titt){
  .#{$titt}__tittle {
    text-align: right;
    p, .section-tittle__line-under{
      right: 0;
    }
    margin: 90px 0 65px;
    @media ( max-width: $lg ) {
      margin: 60px 0;
    }
    @media ( max-width: 550px ) {
      margin: 50px 0 30px;
    }

  }
  .#{$titt}__sub-tittle {
    text-align: right;
    @include fontface($font-primary,13px,$dusty-gray,normal);
    margin-top: 25px;
    display: inline-block;
    width: 370px;
    @media (max-width:475px) {
      width: 100%;
    }
  }
}
@mixin reviewArrowStyle(){
  cursor: pointer;
  transition: all .5s;
  z-index: 1;
  font-size: 18px;
  background-color: $yellow;
  position: absolute;
  top: 50%;
  text-align: center;
  color:$white;
  border-radius: 35px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  opacity: 0.5;
  transform: translate(0,-50%);
  &:hover {
      opacity: 1.0;
  }
}

@mixin reviewArrowStyle2(){
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  width: 50px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 1;
  img {
     filter:grayscale(100%);
     filter:contrast(5%);
     transition: all 0.5s;
  }
  &:hover {
      img {
        
        filter:grayscale(0%);
        filter:contrast(100%);
      }
  }
}

@mixin ReviewProcessbar($width){
  width: 63%;
  height: 5px;
  border-radius: 5px;
  background-color: #e6e6e6;
  display: inline-block;
  position: relative;
  &::before {
      content: "";
      width: $width;
      height: 100%;
      background-color: $turquoise;
      position: absolute;
      border-radius: 5px;
  }
}



@mixin PreViewVideo($class) {
  
  .#{$class} {
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: none;
    // position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba($color: #000000, $alpha: 0.9);
    
    &__area {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 70%;
        height: 70%;
        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    &__close {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    &__btn-close {
        cursor: pointer;
        display: block;
        position: absolute;
        color: $white;
        font-size: 30px;
        font-weight: 600;
        right: -30px;
        top: -30px;
    }
  }




  @media(max-width:$lg){
    .#{$class} {
        &__area {
            width: 90%;
        }
    }

  }
  @media(max-width:$md){
    .#{$class} {
        &__area {
            height: 50%;
        }
        &__btn-close {
            right: -10px;
            top: -40px;
        }
    }
  }
  @media(max-width:$sm){
    .#{$class} {
        &__area {
            height: 40%;
        }
    }

  }
}
