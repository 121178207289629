.related-tour {
    padding-bottom: 50px;
    @media (max-width:$sm){
        padding-bottom: 0;
    }
    @extend .col-4-tour;
    &__item {
        padding: 0 15px ;
        .trending-tour-item {
            @include boxShadow(-1px 10px 10px -1px rgba(0, 0, 0, 0.12));
        }
    }
    .trending-tour-item__group-infor__lasting {
        img {
            display: inline;
        }
    }
    &__tittle {
        margin: -15px 0 40px !important;
    }
    .destination-4-2__arrow {
        top: -90px;
        &:hover {
            color: $yellow;
        }
        @media (max-width:$sm){
            top: -60px;
            &--right {
                right: 15px;
            }
            &--left {
                right: 70px;
            }
        }
        
    }

}